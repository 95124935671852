import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const ScavengerHunt = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Fri August 2 - 30 |  Utica</h5>
                    <h1>Eighth Annual Historical Selfie Scavenger Hunt</h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/scavenger_hunt.png"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                    Friday, August 2 - Friday, August 30 from 2:00- 4:00 p.m. 
                       <br/>1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    Join Oneida County History Center for its eighth annual Historical Selfie Scavenger Hunt. Venture on a county or city-wide scavenger hunt to explore the people, places, and events that shaped Oneida County. Document your travels with a photo or selfie and share it on social media. All ages are encouraged to participate in this free program with prizes from local businesses. Pick up or download your clue sheets from August 2 -30.
                    </p>
                    <a href="assets/2024 History Center Scavenger Hunt (Utica Clues).pdf" id="utica-clues" className='main-button'>
                       Utica Clues
                    </a>
                    <a href="assets/2024 History Center Scavenger Hunt (Oneida County Clues).pdf" className='main-button'>
                       Oneida County Clues
                    </a>
                    <p>
                    <br/><br/>On August 2, from 2-4 pm, the History Center will host a launch party with refreshments and games.
                    <br/><br/>To participate, choose a Oneida County or Utica-based hunt and follow the clues to historical sites, monuments, and markers around the greater Mohawk Valley. Take a selfie at each destination after learning about the location’s history. Share your adventure by posting photos and tagging the History Center on Facebook (@OneidaCountyHistory), or Instagram (@ochc_localhistory). Upon completion, visit the History Center at 1608 Genesee Street to share your photos.
                    <br/><br/>The first five participants to finish the hunt will receive a History Center membership and a prize. All children under 18 will receive a participation prize, and participants will be entered to win the grand prize gift basket. Entries must be submitted by Friday, August 30, 2024 at 4:00 pm. Please contact Patrick Reynolds, Director of Public Programs at <a href="mailto:preynolds@oneidacountyhistory.org">preynolds@oneidacountyhistory.org</a> or 315-735-3642 with any questions. 
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default ScavengerHunt;