import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const Butterfield = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Saturday, September 21 | Utica</h5>
                    <h1> Daniel Butterfield Lecture & Book Signing</h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/butterfield.png"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                        Saturday, September 21, 2024, 2:00 P.M.
                       <br/>1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    Daniel Butterfield is an overlooked and unappreciated Civil War veteran from Oneida County. James S. Pula’s new book explores the complex legacy of this Union General from his battlefield heroics and Medal of Honor to controversies and innovations that shaped the Civil War. The author unravels this enigmatic figure's life in this meticulously researched and long-awaited biography.
                    </p>
                    <p>
                    James S. Pula is a professor of history at Purdue University Northwest and the former editor of Gettysburg Magazine. His books include For Liberty and Justice: A Biography of Brigadier General Wlodzimierz B. Krzyzanowski; The Sigel Regiment: A History of the 26th Wisconsin Volunteer Infantry; the two-volume Under the Crescent Moon With the XI Corps in the Civil War; and The Civil War from Its Origins to Reconstruction. He was three times awarded the Oskar Halecki Prize for outstanding books as well as the Gambrinus Prize in History from the Milwaukee County Historical Society, 
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default Butterfield;