import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const BattleoftheWilderness = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Sat. May 4 | Utica</h5>
                    <h1>Decimated: The 146th NY at The Battle of the Wilderness with Michael Fisher</h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/battlewilderness.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                        Saturday, May 4, 2024, 2:00 P.M.
                       <br/>1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    May 2024 marks the 160th anniversary of the Battle of the Wilderness. The casualties were high with almost half of the enlisted men and officers becoming casualties. The 146th New York Volunteers from Oneida County had several soldiers among the injured and captured. This program by Michael Fisher will share the personal stories of these men and emphasize the terrible consequences of this deadly Civil War battle.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default BattleoftheWilderness;