import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../LoadFadeIn';
import ReactGA from 'react-ga';

const About = () => {

    useEffect(() => {   

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();

        const menuItems = document.querySelectorAll(".side-menu button");
        const contentSections = document.querySelectorAll(".hidden");

        console.log(contentSections);

        const menuBox = document.querySelectorAll(".side-menu li");
    
        // Show the content of the first option initially
        contentSections[0].classList.remove("hidden");
        menuBox[0].style.backgroundColor = "#004D72";
        menuItems[0].style.color = "#fff";
    
        menuItems.forEach(function (menuItem, index) {
            
            menuItem.addEventListener("click", function (e) {
                e.preventDefault();
                // Hide all content sections
                contentSections.forEach(function (section) {
                    section.classList.add("hidden");
                });

                menuBox.forEach(function (menuBoxSection) {
                    menuBoxSection.style.backgroundColor = "transparent";
                });
                
                menuItems.forEach(function (menuItemsSection) {
                    menuItemsSection.style.color = "black";
                });
                
                // Show the selected content section
                contentSections[index].classList.remove("hidden");
                menuBox[index].style.backgroundColor = "#004D72";
                menuItems[index].style.color = "#fff";
            });
        });


//  MOBILE SIDE MENU

        const mobilemenuItems = document.querySelectorAll(".mobile-side-menu button");

        console.log(contentSections);

        const mobilemenuBox = document.querySelectorAll(".mobile-side-menu li");
    
        // Show the content of the first option initially
        contentSections[0].classList.remove("hidden");
        mobilemenuBox[0].style.backgroundColor = "#004D72";
        mobilemenuItems[0].style.color = "#fff";
    
        mobilemenuItems.forEach(function (menuItem, index) {
            
            menuItem.addEventListener("click", function (e) {
                e.preventDefault();
                // Hide all content sections
                contentSections.forEach(function (section) {
                    section.classList.add("hidden");
                });

                mobilemenuBox.forEach(function (menuBoxSection) {
                    menuBoxSection.style.backgroundColor = "transparent";
                });
                
                mobilemenuItems.forEach(function (menuItemsSection) {
                    menuItemsSection.style.color = "black";
                });
                
                // Show the selected content section
                contentSections[index].classList.remove("hidden");
                mobilemenuBox[index].style.backgroundColor = "#004D72";
                mobilemenuItems[index].style.color = "#fff";
            });
        });

    }, []);

    return (
        <div id="about-page">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-about-header.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>About Us</h1>
                    <h4 className='fade-in-up-load'>
                    Meet our team dedicated to preserving and sharing the past of Oneida County for 
                    present and future generations. We work to make this rich heritage readily 
                    available to researchers, families, and students.
                    </h4>
                </div>
            </section>
            <section id="staff-section">
                <div class="mobile-side-menu">
                    <ul>
                        <li><button aria-label='staff'>Staff Members</button></li>
                        <li><button aria-label='staff'>Board Officers</button></li>
                        <li><button aria-label='staff'>Board Members</button></li>
                        <li><button aria-label='staff'>Emeritus Officers</button></li>
                        <li><button aria-label='staff'>Employment Opportunities</button></li>
                    </ul>
                </div>
                <br/><br/>
                <div class="side-menu-container container">
                    <div class="side-menu">
                        <ul>
                            <li><button aria-label='staff'>Staff Members</button></li>
                            <li><button aria-label='staff'>Board Officers</button></li>
                            <li><button aria-label='staff'>Board Members</button></li>
                            <li><button aria-label='staff'>Emeritus Officers</button></li>
                            <li><button aria-label='staff'>Employment Opportunities</button></li>
                        </ul>
                    </div>
                    <div class="content">
                        <div id="content1" class="hidden">
                            <div className='row'>
                                <div className='image-body col-lg-6'>
                                    <img decoding='async' loading='lazy'  src="assets/about/staff/rebecca-mclain.jpg" alt='staff member'/>
                                </div>
                                <div className='staff-text col-lg-6'>
                                    <h2>Rebecca McLain</h2>
                                    <h3>Executive Director</h3>
                                    <p>
                                    Rebecca started at the History Center as a volunteer in 2015 and worked as the community outreach coordinator and director of 
                                    education and outreach before taking her current role in September 2020. She holds a B.A. in anthropology from CUNY Hunter and 
                                    an M.A. in anthropology from Louisiana State University. She has worked and volunteered at multiple museums and historical 
                                    societies, including the LSU Rural Life Museum in Baton Rouge, LA, and the Omaha Children's Museum in Nebraska. She previously 
                                    taught anthropology courses at Utica University and SUNY Poly.
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='image-body col-lg-6'>
                                    <img decoding='async' loading='lazy'  src="assets/about/staff/Patrick-Reynolds.webp" alt='staff member'/>
                                </div>
                                <div className='staff-text col-lg-6'>
                                    <h2>Patrick Reynolds</h2>
                                    <h3>Director fo Public Programs</h3>
                                    <p>
                                    Patrick is a graduate of the Cooperstown Graduate Program in museum studies and has worked at museums throughout the United States. 
                                    He brings a wealth of experience from exhibition development and design to managing public programs and outreach. He has worked 
                                    in museums throughout the United States including Hanford Mills Museum, Berks County Historical Society, and the Henry Ford Museum 
                                    of American Innovation. He joined the History Center in 2021 and is responsible for public programming and exhibitions.
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='image-body col-lg-6'>
                                    <img decoding='async' loading='lazy'  src="assets/about/staff/mike_disotelle.jpg" alt='staff member'/>
                                </div>
                                <div className='staff-text col-lg-6'>
                                    <h2>Mike Disotelle</h2>
                                    <h3>Research Librarian</h3>
                                    <p>
                                    Mike Disotelle joined the History Center team in January 2025. He has an extensive background in education, public history, and 
                                    digital collections. Mike holds a B.A. degree in history from Utica College, an M.S. degree in social studies education with a 
                                    concentration in history from SUNY Oneonta, and an Advanced Certificate of Graduate Study in public history from Empire State 
                                    University. He taught middle and high school history for 20 years and spent 12 years as an adjunct professor of history at Utica 
                                    College. Most recently, he was the public historian at the Ilion Free Public Library, where he answered all local history research 
                                    requests, managed the local history archives, and engaged the community through digital and social media and occasional history talks.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div id="content2" class="hidden">
                            <div>
                                <h2>Julie Gederos</h2>
                                <h3>Board President</h3>
                            </div>
                            <div>
                                <h2>Jason Mitchell</h2>
                                <h3>Vice President</h3>
                            </div>
                            <div>                    
                                <h2>John Hobika Jr.</h2>
                                <h3>Treasurer</h3>
                            </div>
                            <div>    
                                <h2>Alecia Pendasulo</h2>
                                <h3>Secretary</h3>
                            </div>
                        </div>
                        <div id="content3" class="hidden">
                            <div>                    
                                <h2>Lacey Carpenter</h2>
                                <h2>Jonathan Fiore</h2>
                                <h2>Mary Hayes Gordon</h2>
                                <h2>C. Sonia Martinez</h2>
                                <h2>Catherine McEnroe</h2>
                                <h2>Lou Parrotta</h2>
                            </div>
                        </div>
                        <div id="content4" class="hidden">
                            <div>    
                                <h2>Joe Kelly</h2>
                                <h2>Robert Stronach</h2>
                                <br/><br/><br/><br/><br/><br/><br/>
                            </div>
                        </div>
                        <div id="content4" class="hidden">
                            <div>    
                                <div className='row'>
                                    <div className='image-body col'>
                                        <img decoding='async' loading='lazy'  src="assets/librarian_job.jpg" alt='staff member'/>
                                    </div>
                                    <div className='image-body col'>
                                        <img decoding='async' loading='lazy'  src="assets/librarian_job2.JPG" alt='staff member'/>
                                    </div>
                                    <div className='image-body col'>
                                        <img decoding='async' loading='lazy'  src="assets/librarian_job3.JPG" alt='staff member'/>
                                    </div>
                                </div>
                                <h2>Research Librarian</h2>
                                <p>
                                Oneida County History Center (OCHC) seeks a qualified candidate to serve as its Research Librarian who is responsible for managing the daily operations of the Tharratt Gilbert Best Research Library. This vital role is the primary point of contact for the public and volunteers. The ideal candidate has library, archive, or museum experience, excellent customer service skills, an interest in history, and an eagerness to learn new things. 
                                <br/><br/>
                                A full job description and qualifications can be found <a href="https://docs.google.com/document/d/1kD3TApJlbvnPn8BagqdPdnfU_qRBe0sy/edit?usp=sharing&ouid=118171342380123146117&rtpof=true&sd=true" target="_blank">here</a>.
                                </p>
                                <br/>
                                <p>
                                    To apply: 
                                    <li>
                                        Complete the <a href="https://forms.gle/vVfGZQ5L4JMNQCnD7" target="_blank">online application</a>
                                    </li>
                                    <li>
                                        Submit your resume/cv
                                    </li>
                                    <li>
                                        Submit one-page cover letter addressing your qualifications and interest 
                                    </li>
                                    <br/>
                                        Send submissions to Rebecca McLain at <a href="rmclain@oneidacountyhistory.org" target="_blank">rmclain@oneidacountyhistory.org</a> before December 5, 2024. Applications will be reviewed on a rolling basis until the position is filled.
                                </p>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default About;