import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const AirplaneTales = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Sat April 5 | Utica</h5>
                    <h1><i>“Airplane Tales: The Home Front Edition 1941- 1945”</i>with Jim and Connie Coulthart </h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/airplanetales.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                    Saturday, April 5,  2:00 P.M. 
                       <br/>1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    Jim and Connie Coulthart, a husband and wife team, will present aspects of life on the American Homefront during WW2. Connie will focus on the domestic perspective during those times, while Jim will present information on the military, industrial mobilization, and business aspects. Also included is the largely untold story of a thwarted Nazi sabotage plot, complete with a still missing plundered treasure and the clues furnished to find it.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default AirplaneTales;