import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';


const ThreeHarriets = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>POSTPONED DUE TO WEATHER</h5>
                    <h1>Three Harriets: The Story of Extraordinary Women in the Oneida Community</h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/Oneida-Community.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                    POSTPONED DUE TO WEATHER
                        Stay tuned for a rescheduled date.
                       <br/>1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    The Oneida Community was one of the most radical and successful utopian communes in American history. Much of this is due to the elevated status that women enjoyed inside this Community. Indeed, due to their radical conceptions of sex, family, and gender, women were afforded far more opportunities than their counterparts in the outside world. This presentation will explore women’s experiences in this Community through the biographies of three women named Harriet that both exemplify and complicate the lives of women in the Oneida Community.
                    </p>
                    <p>
                    Thomas A. Guiler is the Director of Museum Affairs at the Oneida Community Mansion House.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default ThreeHarriets;