import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const SweetCiderDaysMotts = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Sat March 22 | Utica</h5>
                    <h1>Sweet Cider Days: Mott’s Applesauce with Jim Ford</h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/motts.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                    Saturday, March 22, 2:00 P.M.
                       <br/>1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    Many are familiar with Bouckville, NY, as the location of the annual Madison-Bouckville Antique Week festivities. However, many people do not know that this is where Mott's company originated. Retired history teacher Jim Ford will share this fascinating story from our local history.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default SweetCiderDaysMotts;