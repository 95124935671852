import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import './styles/App.css';
import './styles/about.css';
import './styles/footer.css';
import './styles/home.css';
import './styles/pagenotfound.css';
import './styles/navbar.css';
import './styles/research.css';
import './styles/support.css';
import './styles/visit.css';

import './component/LoadFadeIn';
import './component/FadeIn';

import Home from './component/Home';
import PageNotFound from './component/PageNotFound';

// ABOUT 

import Contact from './component/About/Contact';
import About from './component/About/About';
import News from './component/About/News';
import OurHistory from './component/About/OurHistory';

// NEWS PAGES

import NewsPage1 from './component/About/News/NewsPage1';
import NewsPage2 from './component/About/News/NewsPage2';
import NewsPage3 from './component/About/News/NewsPage3';
import NewsEllenLuley from './component/About/News/NewsEllenLuley';
import NewsFiveFamilies from './component/About/News/NewsFiveFamilies';
import NewsTelethon24 from './component/About/News/NewsTelethon24';
import NewsMVGives2024 from './component/About/News/NewsMVGives2024';

// RESEARCH 

import Education from './component/Research/Education';
import Research from './component/Research/Research';
import ResearchForm from './component/Research/ResearchForm';
import ResearchRequest from './component/Research/ResearchRequest';
import VisitResearch from './component/Research/VisitResearch';

// SUPPORT

import Membership from './component/Support/Membership';
import SupportUs from './component/Support/SupportUs';
import Volunteer from './component/Support/Volunteer';
import Telethon from './component/Support/Telethon';

// VISIT

import Events from './component/Visit/Events';
import Exhibits from './component/Visit/Exhibits';
import Programs from './component/Visit/Programs';
import Tours from './component/Visit/Tours';
import VisitUs from './component/Visit/VisitUs';

// PROGRAMS 


import Market from './component/Visit/Programs/Market';
import StoryHourJuly from './component/Visit/Programs/StoryHourJuly';
import OpenHouseJuly from './component/Visit/Programs/OpenHouseJuly';
import ScavengerHunt from './component/Visit/Programs/ScavengerHunt';
import StoryHourAugust from './component/Visit/Programs/StoryHourAugust';
import OriskanyBattle from './component/Visit/Programs/OriskanyBattle';


import ThreeHarriets from './component/Visit/Programs/ThreeHarriets';
import EasterPotato from './component/Visit/Programs/EasterPotato';
import FarminIt from './component/Visit/Programs/FarminIt';
import FarminIt2 from './component/Visit/Programs/FarminIt2';
import Landmark50 from './component/Visit/Programs/Landmarks50';
import NationalHistoricMarkerDay from './component/Visit/Programs/NationalHistoricMarkerDay';

import BehindtheScenesJune from './component/Visit/Programs/BehindtheScenesJune';
import AdirondackMysteries from './component/Visit/Programs/AdirondackMysteries';
import MothersdaySipandPaint from './component/Visit/Programs/MothersdaySipandPaint';
import BattleoftheWilderness from './component/Visit/Programs/BattleoftheWilderness';

import StoryHourMay from './component/Visit/Programs/StoryHourMay';
import UticaPotteryJune from './component/Visit/Programs/UticaPotteryJune';
import OpenHouseProctor from './component/Visit/Programs/OpenHouseProctor';

import NewsHOF2024 from './component/About/News/NewsHOF2024';
import NewsUticaBoo from './component/About/News/NewsUticaBoo';

import ReactGA from 'react-ga';
import FiveFamilies from './component/Visit/Programs/FiveFamilies';
import Butterfield from './component/Visit/Programs/Butterfield';
import OneidaCommunity from './component/Visit/Programs/OneidaCommunity';
import BearsStoryHour from './component/Visit/Programs/BearsStoryHour';
import NewsHallofFame2024 from './component/About/News/NewsHallofFame2024';

import Zogby from './component/Visit/Programs/Zogby';
import LostHistory from './component/Visit/Programs/LostHistory';
import Tuesday from './component/Visit/Programs/Tuesday';
import Gerritt from './component/Visit/Programs/Gerritt';
import ZachLewis from './component/Visit/Programs/ZachLewis';
import Wampum from './component/Visit/Programs/Wampum';

import StoryHourNovember from './component/Visit/Programs/StoryHourNovember';
import Baca from './component/Visit/Programs/Baca';
import StoryHourNutcracker from './component/Visit/Programs/StoryHourNutcracker';
import Funtrak from './component/Visit/Programs/Funtrak';

import NotSoTinyNutcrackers from './component/Visit/Programs/NotSoTinyNutcrackers';
import SallyRoeschWagner from './component/Visit/Programs/SallyRoeschWagner';
import AnnualMeeting2024 from './component/Visit/Programs/AnnualMeeting2024';

import StoryHourJan from './component/Visit/Programs/StoryHourJan';
import BTSJan from './component/Visit/Programs/BTSJan';
import BestLand from './component/Visit/Programs/BestLand';
import VintageValentines from './component/Visit/Programs/VintageValentines';
import NaacpFeb from './component/Visit/Programs/NaacpFeb';

import ErieImmigrants from './component/Visit/Programs/ErieImmigrants';
import ValentineWorkshop from './component/Visit/Programs/ValentineWorkshop';
import StoryHourFeb from './component/Visit/Programs/StoryHourFeb';
import GreaterUtica from './component/Visit/Programs/GreaterUtica';

import PromiscuousAssemblies from './component/Visit/Programs/PromiscuousAssemblies';
import SweetCiderDaysMotts from './component/Visit/Programs/SweetCiderDaysMotts';
import AirplaneTales from './component/Visit/Programs/AirplaneTales';

import Boilermaker from './component/Visit/Programs/Boilermaker';
import StoryHourApril from './component/Visit/Programs/StoryHourApril';
import HistoricMarker from './component/Visit/Programs/HistoricMarker';
import Dispirito from './component/Visit/Programs/DIspirito';

import GenealogyWorkshop from './component/Visit/Programs/GenealogyWorkshop';
import SipAndPaint from './component/Visit/Programs/SipAndPaint';


ReactGA.initialize('G-Q9KX2MMJHX');


function App() {
  return (
    <HelmetProvider>
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route path="" element={<Home />} />
        <Route path="about" element={<About/>} />
        <Route path="contact" element={<Contact/>} />
        <Route path="news" element={<News/>} />
        <Route path="our-history" element={<OurHistory/>} />

        <Route path="news-1" element={<NewsPage1/>} />
        <Route path="news-2" element={<NewsPage2/>} />
        <Route path="news-3" element={<NewsPage3/>} />
        <Route path="news-ellen-luley" element={<NewsEllenLuley/>} />
        <Route path="news-five-families" element={<NewsFiveFamilies/>} />
        <Route path="news-telethon-2024" element={<NewsTelethon24/>} />
        <Route path="news-hof-2024" element={<NewsHOF2024/>} />
        <Route path="news-utica-boo" element={<NewsUticaBoo/>} />
        <Route path="news-mv-gives-2024" element={<NewsMVGives2024/>} />
        <Route path="news-hall-of-fame-2024" element={<NewsHallofFame2024/>} />

        {/* <Route path="education-and-resources" element={<Education/>} /> */}
        <Route path="research-and-learn" element={<Research/>} />
        <Route path="research-form" element={<ResearchForm/>} />
        <Route path="research-request" element={<ResearchRequest/>} />
        <Route path="visit-research-library" element={<VisitResearch/>} />

        <Route path="membership" element={<Membership/>} />
        <Route path="support-us" element={<SupportUs/>} />
        <Route path="volunteer" element={<Volunteer/>} />
        <Route path="telethon" element={<Telethon/>} />

        <Route path="events" element={<Events/>} />
        <Route path="exhibits" element={<Exhibits/>} />
        <Route path="programs-and-events" element={<Programs/>} />
        {/* <Route path="tours-and-rentals" element={<Tours/>} /> */}
        <Route path="visit" element={<VisitUs/>} />

        <Route path="three-harriets" element={<ThreeHarriets/>} />
        <Route path="easter-potato" element={<EasterPotato/>} />
        <Route path="farm-in-it" element={<FarminIt/>} />
        <Route path="farm-in-it-april-16" element={<FarminIt2/>} />
        <Route path="landmarks-at-50" element={<Landmark50/>} />
        <Route path="national-historic-marker-day-1" element={<NationalHistoricMarkerDay/>} />

        <Route path="/behind-the-scenes-august" element={<BehindtheScenesJune/>} />
        <Route path="/adirondack-mysteries" element={<AdirondackMysteries/>} />
        <Route path="/mothers-dau-sip-and-paint" element={<MothersdaySipandPaint/>} />
        <Route path="/battle-of-the-wilderness" element={<BattleoftheWilderness/>} />

        <Route path="story-hour-may" element={<StoryHourMay/>} />
        <Route path="utica-pottery-june" element={<UticaPotteryJune/>} />
        <Route path="open-house-proctor" element={<OpenHouseProctor/>} />

        <Route path="market" element={<Market/>} />
        <Route path="openhouse-july" element={<OpenHouseJuly/>} />
        <Route path="oriskany-battle" element={<OriskanyBattle/>} />
        <Route path="scavenger-hunt" element={<ScavengerHunt/>} />
        <Route path="storyhour-august" element={<StoryHourAugust/>} />
        <Route path="story-hour-july" element={<StoryHourJuly/>} />

        <Route path="tunes-titles-and-intent" element={<FiveFamilies/>} />
        <Route path="butterfield" element={<Butterfield/>} />
        <Route path="oneida-community" element={<OneidaCommunity/>} />
        <Route path="bears-story-hour" element={<BearsStoryHour/>} />

        <Route path="zogby" element={<Zogby/>} />
        <Route path="lost-history" element={<LostHistory/>} />
        <Route path="tuesday-night-big-band" element={<Tuesday/>} />
        <Route path="gerritt-smith" element={<Gerritt/>} />
        <Route path="zach-lewis" element={<ZachLewis/>} />
        <Route path="wampum" element={<Wampum/>} />

        <Route path="wampum" element={<Wampum/>} />
        <Route path="story-hour-november" element={<StoryHourNovember/>} />
        <Route path="baca" element={<Baca/>} />
        <Route path="/story-hour-nutcracker" element={<StoryHourNutcracker/>} />
        <Route path="/not-so-tiny-nutcrackers" element={<NotSoTinyNutcrackers/>} />
        <Route path="funtrak" element={<Funtrak/>} />

        <Route path="women-voted-on-this-land-before-columbus" element={<SallyRoeschWagner/>} />
        <Route path="annual-meeting-2024" element={<AnnualMeeting2024/>} />

        <Route path="story-hour-january" element={<StoryHourJan/>} />
        <Route path="bts-january" element={<BTSJan/>} />
        <Route path="best-land" element={<BestLand/>} />
        <Route path="vintage-valentines" element={<VintageValentines/>} />
        <Route path="naacp-february" element={<NaacpFeb/>} />

        <Route path="erie-immigrants" element={<ErieImmigrants/>} />
        <Route path="valentine-workshop" element={<ValentineWorkshop/>} />
        <Route path="story-hour-feb" element={<StoryHourFeb/>} />
        <Route path="greater-utica" element={<GreaterUtica/>} />

        <Route path="promiscuous-assemblies" element={<PromiscuousAssemblies/>} />
        <Route path="sweet-cider-days-motts" element={<SweetCiderDaysMotts/>} />
        <Route path="airplane-tales" element={<AirplaneTales/>} />

        <Route path="boilermaker" element={<Boilermaker/>} />
        <Route path="story-hour-april" element={<StoryHourApril/>} />
        <Route path="national-historic-marker-day" element={<HistoricMarker/>} />
        <Route path="henry-dispirito" element={<Dispirito/>} />

        <Route path="genealogy-workshop" element={<GenealogyWorkshop/>} />
        <Route path="sip-and-paint" element={<SipAndPaint/>} />

      </Routes>
    </HelmetProvider>
  );
}

export default App; 
