import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const HistoricMarker = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Fri. April 25 | Utica</h5>
                    <h1>National Historic Marker Day</h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/historicmarker.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                        Friday April 25, 3:00 P.M. 
                       <br/>Oriskany Battlefield State Historic Site<br/>
                       7801 NY-69, Oriskany, NY 13424
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    People everywhere enjoy historic markers, from roadsides and parks to historic sites or even their own neighborhoods. According to The Historical Marker Database, there are more than 190,000 markers in the United States, but many have not received cleaning or care for years.
                    </p>
                    <p>
                    That’s where National Historic Marker Day steps in! This service-oriented event brings people together to maintain their markers by cleaning them and providing an opportunity to celebrate and preserve history. Created by the William G. Pomeroy Foundation, this fun and family-friendly celebration is held the last Friday of every April. We invite you to join us for the fourth annual National Historic Marker Day, Friday, April 25, 2025. We will be cleaning the markers at the Oriskany Battlefield  starting at 3:00 pm. Contact the history center for details and a map.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default HistoricMarker;