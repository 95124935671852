import React from 'react';
import { useEffect } from 'react';
import { FadeIn } from '../FadeIn';
import { LoadFadeIn } from '../LoadFadeIn';
import ReactGA from 'react-ga';


const Research = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-research-learn-header.png' alt='Header'></img>                
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Research & Learn</h1>
                    <h4 className='fade-in-up-load'>
                    Explore the resource in the Tharratt Gilbert Best Library to discover Oneida County history.
                    </h4>
                </div> 
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <h2 className='fade-in-up'>Research Library</h2>
                    <p className='fade-in-up'>
                    Discover Oneida County history at the the Colonel Tharratt Gilbert Best Research Library. The library is 
                    home to more than 250,000 documents and books, tens of thousands of images including photographs, 
                    paintings, slides, and drawings, and thousands of artifacts. Many are rare collection items that provide 
                    invaluable information for historians, educators, genealogists, and researchers. Read below to see how you 
                    access the materials in our collections.
                    </p>

                    <div className="research-img-right">
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div class="container home-shop-content">
                                    <div class="home-shop-content-text">
                                        <h1 className='fade-in-up'>Visit</h1>
                                        <p className='fade-in-up'>
                                        Visit the library to investigate your family heritage, browse through old photos, or learn about your community.
                                        </p>
                                    </div>
                                    <a className="main-button-white" href="/visit-research-library">Learn More
                                    </a>                   
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <img decoding='async' loading='lazy' alt='looking at documents' src="assets/TEMP-visit.png"/>
                            </div>
                        </div>
                    </div>

                    <div className="research-img-left">
                        <div className='row'>
                            <div className='col-lg-6'>
                                <img decoding='async' loading='lazy' alt='set of books' src="assets/research/research-rotated.webp"/>
                            </div>
                            <div className='col-lg-6'>
                                <div class="container home-shop-content">
                                    <div class="home-shop-content-text">
                                        <h1 className='fade-in-up'>Research Services</h1>
                                        <p className='fade-in-up'>
                                        Can't make it to the library or seek some help with your research? We've got you covered!
                                        </p>
                                    </div>
                                    <a className="main-button" href="/research-request">Learn More
                                    </a>                   
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="research-img-right">
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div class="container home-shop-content">
                                    <div class="home-shop-content-text">
                                        <h1 className='fade-in-up'>Online Collections</h1>
                                        <p className='fade-in-up'>
                                        Explore our online collections on NY Heritage.
                                        </p>
                                    </div>
                                    <a className="main-button-white" target="_blank" href="https://nyheritage.org/index.php/organizations/oneida-county-history-center">Learn More
                                    </a>                   
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <img decoding='async' loading='lazy' alt='screenshot of online collection' src="assets/research/collections.jpg"/>
                            </div>
                        </div>
                    </div>

                    <div className="research-img-left">
                        <div className='row'>
                            <div className='col-lg-6'>
                                <img decoding='async' loading='lazy' alt='group of interns in front of building' src="assets/TEMP-education.png"/>
                            </div>
                            <div className='col-lg-6'>
                                <div class="container home-shop-content">
                                    <div class="home-shop-content-text">
                                        <h1 className='fade-in-up'>Education</h1>
                                        <p className='fade-in-up'>
                                        Discover educational opportunities for K-12 and college students. Contact us for more information.
                                        </p>
                                    </div>
                                    <a className="main-button" href="mailto:rmclain@oneidacountyhistory.org">Contact Us
                                    </a>                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Research;