import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const FiveFamilies = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Saturday, September 14 | Utica</h5>
                    <h1>Tunes, Titles & Intent: Monk Rowe and The Five Families Ensemble</h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/fivefamilies_sep.jpg"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                        Saturday, September 14, 2024, 2:00 P.M.
                       <br/>1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    Join Monk Rowe and The Five Families Ensemble for an engaging program of classic
                    instrumental works and original compositions. The backstory behind the composers’
                    intent and choice of title will be explored and an audience member will provide a name
                    for an untitled new Monk composition. Admission is free.
                    </p>
                    <p>
                    Instrumental music compositions are written for a variety of reasons. The initial
                    inspiration may be profound or practical, just as the meaning behind a particular tune
                    can be obvious or nonexistent. A mostly abstract art form, instrumental music is often
                    difficult to title. Monk Rowe and his dynamic chamber group will perform engaging
                    classic tunes and original work and provide the backstory behind the title and
                    composer’s intention. An untitled, premier performance will be named by an audience
                    member. 
                    </p>
                    <p>
                    The Five Families Ensemble includes: Kristen Kopf, cello; Liz Simchik, violin; Scott Rutledge, trumpet; Evan Duchene, drums; and Monk Rowe, saxophone, keyboard.
                    </p>
                    <p>
                    This project is made possible with funds from the Statewide Community Regrants Program, a regrant program of the New York State Council on the Arts, with the support of the Office of the Governor and the New York State Legislature, and administered by CNY Arts. 
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default FiveFamilies;