import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const Zogby = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Sat. October 19 | Utica</h5>
                    <h1>Oneida County History Center to Premiere Documentary about John Zogby                    </h1>
                    {/* <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/zogby.jpg"/> */}
                    <iframe className='zogby-vid' src="https://www.youtube.com/embed/8dc_yzkuAwY?si=YPa3C9ZjYiyMKLe8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <br/><br/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                    Saturday, October 19, 2:00 P.M. Zogby Film<br/>
                    Wednesday, October 23, 7:00 P.M.  Zogby Film<br/>
                       <br/>1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    People, Not Numbers: John Zogby, Living Legend, is scheduled to premiere at Oneida County History Center on Saturday, October 19 at 2:00 p.m. with a repeat screening on Wednesday, October 23 at 7:00 p.m. This feature-length film documentary by Dennis Dewey tells the story of John Zogby's life as the son of an undocumented immigrant from Lebanon and a politically savvy Lebanese mother, John's experience growing up in East Utica and his rise to international recognition as the pre-eminent political pollster of the late 20th and early 21st Centuries. Zogby was named a "Living Legend" by the History Center in 2006.
                    </p>
                    <p>
                    John Zogby will be on hand to sign copies of his new book, Beyond the Horse Race: How to Read Polls and Why We Should. Popcorn and refreshments will be served at both screenings.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default Zogby;