import React from 'react';
import { useEffect } from 'react';
import { FadeIn } from '../FadeIn'; 
import { LoadFadeIn } from '../LoadFadeIn'; 
import ReactGA from 'react-ga';

const VisitResearch = () => {

    useEffect(() => {


        ReactGA.pageview(window.location.pathname + window.location.search);

        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id="visit-research-library-page">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/research/visit-lib.jpg' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Visiting the Research Library</h1>
                    <h4 className='fade-in-up-load'>
                    Visit the library and conduct your own research. Investigate your family heritage, browse through old photos, or learn about the history of your property.
                    </h4>
                </div> 
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <p className='fade-in-up'>
                        Visitors are admitted by appointment Tuesday through Saturday between 10 a.m. - 4p.m.  
                        Call <span><a href="tel:315-735-3642">315-735-3642</a></span> or  
                        contact <span><a href="mailto:research@oneidacountyhistory.org">research@oneidacountyhistory.org</a></span> to
                        schedule your appointment.  If you wish to visit in the next 
                        24-48 hours please call.  Subject to staff and volunteer availability.
                    </p>
                    <p>
                        We are a volunteer-run nonprofit, so keep in mind that the fees do not cover the cost of the service.  
                        We are able to subsidize the costs associated with researching and preserving the records in our 
                        possession through the generosity of our members.
                    </p>
                    {/* <h2>Research Fees</h2> */}
                    <div className="research-img-left">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <img decoding='async' loading='lazy' alt='looking at documents' src="assets/TEMP-visit.png"/>
                            </div>
                            <div className='col-lg-8'>
                                <div class="container">
                                    <div class="home-shop-content-text">
                                        <h2>Library Use</h2>
                                        <p className='fade-in-up'>
                                        Admission is free for OCHC members and $10 per visit for each non-member. Students and educators working 
                                        on school projects are admitted for free with valid ID. Copy and reproduction fees apply.
                                        </p>
                                    </div>
                                    <a className="main-button"
                                        target="_blank"
                                     href="/assets/usefeepolicy.pdf">RESEARCH LIBRARY USE  & FEE POLICY
                                    </a>                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="research-img-left">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <img decoding='async' loading='lazy' alt='set of books' src="assets/TEMP-books.png"/>
                            </div>
                            <div className='col-lg-8'>
                                <div class="container">
                                    <div class="home-shop-content-text">
                                        <h2>Research Services</h2>
                                        <p className='fade-in-up'>
                                        Can't make it to the library or seek some help with your research? We can investigate 
                                        materials available in our collections and respond to your historical or genealogical 
                                        research request for a small prepaid fee.
                                        </p>
                                    </div>
                                    <a className="main-button" 
                                    href="/research-request"
                                    >REQUEST RESEARCH
                                    </a>                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="research-img-left">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <img decoding='async' loading='lazy' alt='set of books' src="assets/TEMP-research-services.png"/>
                            </div>
                            <div className='col-lg-8'>
                                <div class="container">
                                    <div class="home-shop-content-text">
                                        <h2>Photo Reproductions</h2>
                                        <p className='fade-in-up'>
                                        Photo duplication services are available for print and digital images. Photo prints 
                                        start at $10.00 for an 8” x 10” image. Please call for additional sizes and prices,
                                        and see the Request Permission to Publish & Reproduce for digital image information.
                                        </p>
                                    </div>
                                    <a className="main-button" 
                                    target="_blank" 
                                    href="https://drive.google.com/file/d/1SryRCbFUR8H-MIO7UWle6Fz6z5Z-0qoB/view?usp=sharing"
                                    >DIGITAL PHOTO REPRODUCTIONS
                                    </a>                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="research-img-left last-item">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <img decoding='async' loading='lazy' alt='set of books' src="assets/research/donate-research-library.webp"/>
                            </div>
                            <div className='col-lg-8'>
                                <div class="container">
                                    <div class="home-shop-content-text">
                                        <h2>Donations</h2>
                                        <p className='fade-in-up'>
                                        Interested in making a donation to the research library? The History Center accepts donations 
                                        for our collections that support our mission. Click below to read through our Donation Policy 
                                        and contact us to discuss your items. 
                                         </p>
                                    </div>
                                    <a className="main-button" 
                                    target="_blank" 
                                    href="https://drive.google.com/file/d/1Up5Ar6dUZbDeYVzOTi5K6zxdA3yA--bv/view"
                                    >DONATION POLICY
                                    </a>                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className='fade-in-up'>
                        The History Center does not maintain records for vital statistics. To obtain birth, death, or marriage records, 
                        contact the clerk of the town or city where the birth, death, or marriage occurred. For wills, contact the county 
                        surrogate court. Visit <span><a target="_blank" href="https://oneidacountyny.gov/departments/county-clerk/vital-records-birth-death-marriage/">https://oneidacountyny.gov/departments/county-clerk/vital-records-birth-death-marriage/</a></span> for 
                        more information on Oneida County records.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default VisitResearch;