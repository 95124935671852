import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../../LoadFadeIn';
import { FadeIn } from '../../FadeIn';
import ReactGA from 'react-ga';

const GenealogyWorkshop = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/TEMP-programs.png' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Programs & Events</h1>
                    <h4 className='fade-in-up-load'>
                    No matter who you are, there's always something new to learn at The Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section program-subpage'>
                <div className='container'>
                    <h5>Sat. May 3 | Utica</h5>
                    <h1>Hidden in the Branches: Uncovering Ancestral Stories through Unexpected Pathways </h1>
                    <img decoding='async' loading='lazy' alt='upcoming program' src="assets/visit/programs/genealogy.png"/>
                    <h2>Time & Location</h2>
                    <p className='fade-in-up'>
                    Saturday, May 3, 10:00 A.M. - 12:00 P.M.  <br/>
                    1608 Genesee St, Utica, NY 13502, USA
                    </p>
                    <h2>About the Event</h2>
                    <p>
                    Professional genealogist/historian Pamela Vittorio will discuss how less-frequently used or unfamiliar sources can help fill in gaps in your family history. When passenger lists, birth records, or censuses are missing, where can you look for your ancestors to prove their identities or relationships?  Find out what might be hidden in the branches of your family tree at this genealogy workshop. Tickets are $10 for members and $15 for non-members and can be purchased following <a target="_blank" href="https://oneidacountyhistory.square.site/product/genealogy-workshop-with-pamela-vittorio-hidden-in-the-branches-uncovering-ancestral-stories-through-unexpected-pathways-1-/665?cp=true&sa=false&sbp=false&q=true">this link</a> or call <a href="tel:315-735-3642">315-735-3642</a>.
                    </p>
                    <p>
                    Pamela Vittorio is a professional historian and genealogist. She manages the "Finding Their Routes—Family History & Genealogy" Facebook page, conducts research for various clients and is a member of the Association of Professional Genealogists.
                    </p>
                    <a href="/programs-and-events" className='main-button'>
                        <i className='fa fa-arrow-left'></i> Back to All Programs
                    </a>
                </div>
            </section>
        </div>
    );
}

export default GenealogyWorkshop;