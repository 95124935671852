import React from 'react';
import { useEffect } from 'react';
import { FadeIn } from '../FadeIn';
import { LoadFadeIn } from '../LoadFadeIn'; 
import ReactGA from 'react-ga';

const Membership = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);

        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id="membership-page">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/NEW-member-header.jpg' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Membership</h1>
                    <h4 className='fade-in-up-load'>
                    Join our community of members who are passionate about history, heritage, and education.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <h2 className='fade-in-up'>Membership</h2>
                    <p className='fade-in-up'>
                    Join us or renew to help keep local history vibrant and available for all members of our community! Membership is incredibly impactful because the History Center is funded by private sources and relies on donations as a primary source of income. Become a member today and join our almost 500 members who support local history. 
                    </p>
                    <p className='fade-in-up'>
                    Membership supports public programs, rotating exhibits, educational opportunities for all ages, and preservation of a vast archival and artifact collection. Your generous participation allows the History Center to continue its mission for present and future generations.
                    </p>
                    <br/>

                    <h2 className='fade-in-up'>Membership Benefits</h2>

                    {/* <h5 className='fade-in-up'>A subscription to the Oniota</h5>
                    <p className='fade-in-up'>
                    Volunteer to staff the History Center bookstore on Tuesday through Friday mornings. Greet visitors, 
                    checkout customers, and answer the phone. Basic computer/ smart phone skills necessary. Volunteer 
                    for one day a week or more!
                    </p>

                    <h5 className='fade-in-up'>Free use of the Research Library</h5>
                    <p className='fade-in-up'>
                    The History Center hosts more than 40 programs a year! Join us to staff registration tables and greet our visitors.
                    </p>

                    <h5 className='fade-in-up'>10% discount at the on-site Bookstore</h5>
                    <p className='fade-in-up'>
                    Over 30,000 images and we need help identifying many of our photos. Join us as a photo 
                    detective and help us solve our history mysteries. Those with a knowledge of the local area 
                    are strongly encouraged to inquire! Basic computer skills preferred, but not required.
                    </p>

                    <h5 className='fade-in-up'>Discounted admission to special events</h5>
                    <p className='fade-in-up'>
                    Custodial, administrative, and membership support. Contact the History Center at 315-735-3642 for more information.
                    </p>
                    <h5 className='fade-in-up'>The rewarding opportunity to support your community</h5> */}
                    <p className='fade-in-up'>- A subscription to our bimonthly newsletter The Oniota</p>
                    <p className='fade-in-up'>- Free access to the Tharratt Gilbert Best Research Library </p>
                    <p className='fade-in-up'>- 10% discount at our bookstore, both in person and online</p>
                    <p className='fade-in-up'>- Free admission to public programs</p>
                    <p className='fade-in-up'>- Discounted admission to special events such as the Historical Hall of Fame Awards Banquet, workshops, and special programs</p>
                    <p className='fade-in-up'>- The rewarding opportunity to support your community</p>

                    <br/><br/>
                    <h2 className='fade-in-up'>Membership Options</h2>
                    <div class="row member-row">
                        <div class="col-lg-3 member-col">
                            <div className='card'>
                                <div className='member-body'>
                                    <h3>Individual</h3>
                                    <blockquote className='fade-in-up'>
                                    Membership benefits for one individual
                                    </blockquote>
                                    <a href="https://oneidacountyhistory.square.site/product/membership-individual/331?cp=true&sa=false&sbp=false&q=false&category_id=14" 
                                        target="_blank"
                                        className='main-button-white'>SELECT</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 member-col family-option">
                            <div className='card'>
                                <div className='member-body'>
                                    <h3>Family</h3>
                                    <blockquote className='fade-in-up'>
                                    Membership benefits for two adults & all children 18 and younger living in the same household.
                                    </blockquote>
                                    <a href="https://oneidacountyhistory.square.site/product/membership-family-household/333?cp=true&sa=false&sbp=false&q=false&category_id=14" 
                                        target="_blank"
                                        className='main-button-white'>SELECT</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 member-col">
                            <div className='card'>
                                <div className='member-body'>
                                    <h3>Corporate</h3>
                                    <blockquote className='fade-in-up'>
                                    Membership benefits for two adults, web listing, and media recognition
                                    </blockquote>
                                    <a href="https://oneidacountyhistory.square.site/product/membership-corporate/334?cp=true&sa=false&sbp=false&q=false&category_id=14" 
                                        target="_blank"
                                        className='main-button-white'>SELECT</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 member-col">
                            <div className='card'>
                                <div className='member-body'>
                                    <h3>Student</h3>
                                    <blockquote className='fade-in-up'>
                                    Membership benefits for one student up to age 22. Must present valid ID.
                                    </blockquote>
                                    <a href="https://oneidacountyhistory.square.site/product/membership-student/332?cp=true&sa=false&sbp=false&q=false&category_id=14" 
                                        target="_blank"
                                        className='main-button-white'>SELECT</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/><br/>
                    <h2 className='fade-in-up'>Corporate Members</h2>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <p className='fade-in-up'>Adirondack Financial Services Corp.</p>
                            <p className='fade-in-up'>BAND Business Solutions</p>
                            <p className='fade-in-up'>Basloe, Levin & Cuccaro, Ltd</p>
                            <p className='fade-in-up'>Caruso McLean & Co., Inc.</p>
                            <p className='fade-in-up'>Coldwell Banker Faith Properties</p>
                            <p className='fade-in-up'>Daily Sentinel</p>
                            <p className='fade-in-up'>DBJ Wealth Management, NWM</p>
                            <p className='fade-in-up'>DeIorio Foods, Inc.</p>
                            <p className='fade-in-up'>F.X. Matt Brewing, Co., Inc.</p>
                            <p className='fade-in-up'>Festine Nelson LLP</p>
                            <p className='fade-in-up'>Fischer Advocacy Associates LLC</p>
                            <p className='fade-in-up'>Gaetano Construction</p>
                            <p className='fade-in-up'>GPO FCU</p>
                            <p className='fade-in-up'>Hilton & Powers, Cpa's P.C.</p>
                            <p className='fade-in-up'>Jay-K Lumber Co.</p>
                            <p className='fade-in-up'>LaFa Mediterranean by Zeina's</p>
                        </div>
                        <div className='col-lg-6'>
                            <p className='fade-in-up'>MARCH Associates</p>
                            <p className='fade-in-up'>Mercurio's Automotive & Collision</p>
                            <p className='fade-in-up'>NAACP Branch #2167-B</p>
                            <p className='fade-in-up'>Nancy Ford Photography</p>
                            <p className='fade-in-up'>New Hartford Eye Associates</p>
                            <p className='fade-in-up'>Oneida Chpt., Daughters of the American Revolution</p>
                            <p className='fade-in-up'>Scalzo, Zogby and Wittig, Inc.</p>
                            <p className='fade-in-up'>Staffworks</p>
                            <p className='fade-in-up'>Standard Insulating</p>
                            <p className='fade-in-up'>Stanley Theater</p>
                            <p className='fade-in-up'>Towne Engineering</p>
                            <p className='fade-in-up'>UFA Alumni Assn.</p>
                            <p className='fade-in-up'>Utica Bread Artisan Bakery</p>
                            <p className='fade-in-up'>Utica First Insurance Company</p>
                            <p className='fade-in-up'>Wurth NSI</p>
                        </div>
                    </div>
                    <br/><br/>
                    <p>
                    **All memberships are good for one calendar year from date of purchase.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default Membership;