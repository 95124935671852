import React from 'react';
import { useEffect } from 'react';
import { FadeIn } from '../FadeIn';
import { LoadFadeIn } from '../LoadFadeIn';
import ReactGA from 'react-ga';



const Programs = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id="programs-page">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/visit/programs/TEMP-programs.webp' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className="fade-in-up-load">Programs & Events</h1>
                    <h4 className="fade-in-up-load">
                    No matter who you are, there's always something new to learn at Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <h2>Upcoming Events</h2>
                    <div className='row'>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/boilermaker.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Panel Discussion– The Boilermaker: Past, Present & Future</h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, April 12
                                    </p>
                                    <a href="/boilermaker" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/storyhour_april.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Story Hour at the History Center</h4>
                                    <p class="card-text fade-in-up">
                                    Wednesday, April 23
                                    </p>
                                    <a href="/story-hour-april" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/historicmarker.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">National Historic Marker Day</h4>
                                    <p class="card-text fade-in-up">
                                    Friday, April 25
                                    </p>
                                    <a href="/national-historic-marker-day" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/dispirito.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Life and Art of Henry Dispirito with Ashley Hopkins-Benton</h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, April 26
                                    </p>
                                    <a href="/henry-dispirito" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/genealogy.png"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Hidden in the Branches: Uncovering Ancestral Stories through Unexpected Pathways </h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, May 3
                                    </p>
                                    <a href="/genealogy-workshop" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/sippaint.png"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Historical Sip and Paint</h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, May 10
                                    </p>
                                    <a href="/sip-and-paint" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='programs-vids-section container'>
                <div className='container calendar'>
                    <div className='row top'>
                        <div className='col-lg-8'>
                            <h2>View the <span>calendar</span> for more upcoming events and programs!</h2>
                        </div>
                        <div className='col-lg-4'>
                        <a className="video-button" 
                                href="/events"
                                aria-label='calendar'
                                target='_blank'
                            >OCHC Calendar</a>                          
                        </div>
                    </div>
                </div>
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <h2>Past Events</h2>
                    <div className='row'>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/promiscuous.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Oneida County's Women of the Underground Railroad</h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, March 8
                                    </p>
                                    <a href="/promiscuous-assemblies" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/motts.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Sweet Cider Days: Mott's Applesauce with Jim Ford</h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, March 22
                                    </p>
                                    <a href="/sweet-cider-days-motts" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/airplanetales.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">"Airplane Tales: The Home Front Edition 1941-1945"</h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, April 5
                                    </p>
                                    <a href="/airplane-tales" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section>
            <section className='programs-vids-section'>
                <div className='container'>
                    <div className='row top'>
                        <div className='col-lg-6'>
                            <h2>Learn More on Our Youtube Channel!</h2>
                        </div>
                        <div className='col-lg-6'>
                            <a className="video-button" 
                                href="https://www.youtube.com/channel/UCxL6DtlD7yI6ioAsD7z9Oxg"
                                aria-label='Newsletter'
                                target='_blank'
                            >OCHC Youtube</a>                        
                        </div>
                    </div>
                    <div className="row"> 
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/IhvhYPnESTk?si=itQl3f7rIL_1OCpB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>    
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/RUIkT5QVKiw?si=X0QCv5mhVczq5Q2k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div> 
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/ESNb-dUlmJc?si=zPPdpDlVEEjh5LVI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div> 
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/QZfZ2MzbfSc?si=cH26BLfBpA4eom8J" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>   
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Programs;